@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .bz-focus {
    @apply focus:outline-none focus:ring focus:ring-primary focus:border-transparent rounded appearance-none;
  }
  .bz-hover {
    @apply hover:bg-blueGray-100;
  }
  .bz-input-label {
    @apply text-lg sm:text-xl mb-2 font-normal tracking-tight text-secondary text-left sm:tracking-normal;
  }
  .bz-input {
    @apply bg-blueGray-200 border-0 focus:bg-blueGray-100 w-full h-12 px-4 py-4 text-lg font-sans leading-tight text-secondary bz-focus bz-hover;
  }
  .bz-btn-icon {
    @apply flex items-center justify-center rounded-full p-2 text-primary hover:opacity-95 bz-focus bz-hover;
  }
  .bz-btn-outline {
    @apply flex items-center justify-center space-x-2 rounded border-2 border-primary py-2 px-4 text-primary hover:opacity-95 bz-focus focus:ring-offset-2;
  }
  .bz-btn-solid {
    @apply bz-btn-outline bg-primary text-white bz-focus focus:ring-offset-2;
  }
}

@layer base {
  @font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat/Montserrat-Black.ttf") format("truetype");
    font-weight: 900;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat/Montserrat-BlackItalic.ttf") format("truetype");
    font-weight: 900;
    font-style: italic;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat/Montserrat-ExtraBold.ttf") format("truetype");
    font-weight: 800;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf")
      format("truetype");
    font-weight: 800;
    font-style: italic;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
    font-weight: 700;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat/Montserrat-BoldItalic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
    font-weight: 600;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat/Montserrat-SemiBoldItalic.ttf")
      format("truetype");
    font-weight: 600;
    font-style: italic;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
    font-weight: 500;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat/Montserrat-MediumItalic.ttf")
      format("truetype");
    font-weight: 500;
    font-style: italic;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
    font-weight: 400;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat/Montserrat-Italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
    font-weight: 300;
  }

  @font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat/Montserrat-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat/Montserrat-ExtraLightItalic.ttf")
      format("truetype");
    font-weight: 200;
    font-style: italic;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat/Montserrat-ExtraLight.ttf") format("truetype");
    font-weight: 200;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat/Montserrat-Thin.ttf") format("truetype");
    font-weight: 100;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat/Montserrat-ThinItalic.ttf") format("truetype");
    font-weight: 100;
    font-style: italic;
  }
  :root {
    --zip-max: 0;
    --primary-org: #374151;
    --primary-team: var(--primary-org, #374151);
    --primary-brand: var(--primary-team, var(--primary-org, #374151));
    --primary: var(
      --primary-picker,
      var(--primary-brand, var(--primary-team, var(--primary-org, #374151)))
    );
    --secondary-org: #03012b;
    --secondary-team: var(--secondary-org, #03012b);
    --secondary-brand: var(--secondary-team, var(--secondary-org, #03012b));
    --secondary: var(
      --secondary-picker,
      var(
        --secondary-brand,
        var(--secondary-team, var(--secondary-org, #03012b))
      )
    );
    --amplify-primary-color: var(--primary, #374151);
    --amplify-primary-tint: var(--primary, #374151);
    --amplify-primary-shade: var(--primary, #374151);
  }
}

html {
  @apply h-full;
  @apply bg-gray-900;
}

body {
  -webkit-overflow-scrolling: touch;
  @apply h-full;
}

#site-background {
  @apply -z-10;
  @apply fixed;
  @apply inset-0;
  @apply bg-gray-100;
  @apply bg-fixed;
  @apply overscroll-none;
}

#site-background.v2 {
  @apply bg-layout-background;
}

#site-background.v2custom {
  @apply bg-gradient-to-br;
  @apply bg-primary;
  @apply from-primary;
  @apply to-secondary;
  @apply to-50%;
}

input[type="search"]::-webkit-search-cancel-button {
  opacity: 0.2;
  pointer-events: all;
}

.svg-fill-primary path {
  /* @apply fill-primary */
  fill: var(--primary, green);
}

.svg-stroke-secondary path {
  /* @apply fill-primary */
  stroke: var(--secondary, green);
}

.svg-stroke-primary path {
  /* @apply fill-primary */
  stroke: var(--primary, green);
}

.date-picker-container > div {
  @apply w-full h-full;
}

.date-picker-container > div > .react-datepicker {
  @apply border-0 rounded-t-none;
}

.date-picker-container
  > div
  > .react-datepicker
  > .react-datepicker__month-container {
  @apply w-full h-full border-0;
}

.react-datepicker__month-container > .react-datepicker__header {
  @apply w-full h-full bg-gradient-to-br from-blueGray-600 to-blueGray-800 text-white rounded-t-none rounded-tl-none rounded-tr-none;
}

.react-datepicker__month-container
  > .react-datepicker__header
  > .react-datepicker__current-month,
.react-datepicker__month-container
  > .react-datepicker__header
  > .react-datepicker__day-names
  > .react-datepicker__day-name {
  @apply text-white;
}

.react-datepicker__month-container
  > .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  @apply rounded-t-none rounded-tl-none rounded-tr-none;
}

.react-datepicker > .react-datepicker__navigation--next {
  border-left-color: #fff;
}

.react-datepicker > .react-datepicker__navigation--previous {
  border-right-color: #fff;
}

.react-datepicker__month
  > .react-datepicker__week
  > .react-datepicker__day.react-datepicker__day--disabled,
.react-datepicker__month
  > .react-datepicker__week
  > .react-datepicker__day.react-datepicker__day--disabled.react-datepicker__day--keyboard-selected {
  @apply text-gray-300 bg-white cursor-default;
}

.react-datepicker__month
  > .react-datepicker__week
  > .react-datepicker__day,
.react-datepicker__month
  > .react-datepicker__week
  > .react-datepicker__day.react-datepicker__day--keyboard-selected {
  @apply text-black bg-white;
}

.react-datepicker__month
  > .react-datepicker__week
  > .react-datepicker__day:hover
.react-datepicker__month
  > .react-datepicker__week
  > .react-datepicker__day.react-datepicker__day--keyboard-selected:hover {
  @apply bg-blueGray-100 text-black;
}

.react-datepicker__month
  > .react-datepicker__week
  > .react-datepicker__day--in-range,
.react-datepicker__month
  > .react-datepicker__week
  > .react-datepicker__day--in-range.react-datepicker__day--keyboard-selected {
  @apply text-white bg-blueGray-600;
}

.react-datepicker__month
  > .react-datepicker__week
  > .react-datepicker__day--in-selecting-range,
.react-datepicker__month
  > .react-datepicker__week
  > .react-datepicker__day--in-selecting-range.react-datepicker__day--keyboard-selected {
  @apply text-white bg-gray-500;
}

.react-datepicker__month
  > .react-datepicker__week
  > .react-datepicker__day--selected,
.react-datepicker__month
  > .react-datepicker__week
  > .react-datepicker__day--selected.react-datepicker__day--keyboard-selected {
  @apply text-white bg-blueGray-600;
}

.tooltip {
  @apply invisible absolute bg-gray-100 shadow-lg text-sm px-1 rounded border border-primary;
}

.force-scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}

.force-scrollbar::-webkit-scrollbar-track {
  background: white;
  border-radius: 100vh;
}

.force-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,.5);
  border-radius: 100vh;
  border: 7px;
}

.force-scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgba(0,0,0,.5);
}

/* leaflet */

.leaflet-popup .leaflet-popup-content-wrapper, .leaflet-popup .leaflet-popup-tip {
  @apply text-gray-700
}

#heatmap .leaflet-control-container .leaflet-control-zoom {
  @apply relative top-32;
}
#heatmap {
  background: #aad3df;
}
#map {
  background: #aad3df
}

.bg-nav-opacity:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.bg-nav-active {
  background-color: rgba(255, 255, 255, 0.2);
}

.text-shadow-black {
  text-shadow: rgba(0,0,0,0.3) 1px 1px 1px;
}

.text-shadow-white {
  text-shadow: rgba(255,255,255,0.2) 1px 1px 1px;
}
.border-top-left-right-gray-300 {
  border-top:    1px solid  rgb(209 213 219);
  border-right:  1px solid rgb(209 213 219);
  border-left:   1px solid  rgb(209 213 219);
}
